import Typography from "typography"
import wordpress2015Theme from "typography-theme-wordpress-2015"

wordpress2015Theme.baseFontSize=18;
const typography = new Typography(wordpress2015Theme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
