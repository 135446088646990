import React, { useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import { rhythm } from "../utils/typography"
import styles from "./layout.module.css"
import "./layout.css"
import { SuperLeaderBoard } from "./Ads/MediaNet"
import Banner from "./Banner"

const Layout = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false)
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          siteUrl
          title
        }
      }
    }
  `)

  const toggleMenu = () => setShowMenu(!showMenu)

  return (
    <>
      <div className={styles.toggle} onClick={toggleMenu} onKeyDown={toggleMenu} role="button"
           tabIndex={0}>{showMenu ? "×" : "☰"}</div>
      <div className={styles.search}>
        <div className="gcse-search" />
      </div>
      <nav className={showMenu ? styles.showNav : styles.nav}>
        <Link to={"/"}>
          <img src={"/logo.png"} alt={data.site.siteMetadata.title} className={styles.logo} />
        </Link>
        <Link to={"/"} className={styles.navItem}>Home</Link>
        <Link to={"/tags/python/"} className={styles.navItem}>Python</Link>
        <Link to={"/tags/django/"} className={styles.navItem}>Django</Link>
        <Link to={"/tags/frontend/"} className={styles.navItem}>Frontend</Link>
        <Link to={"/tags/career/"} className={styles.navItem}>Career</Link>
        <Link to={"/tags/tools/"} className={styles.navItem}>Tools</Link>
        <Link to={"/about/"} className={styles.navItem}>About</Link>
      </nav>
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(32),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
          textAlign: 'left',
        }}
      >
        <SuperLeaderBoard />
        {children}
        <Banner />
        <footer className={styles.footer}>
          © <Link
          to={"/"}
        >{data.site.siteMetadata.title}</Link>
          {` `}
          {new Date().getFullYear()} | All rights reserved
          <br />
          <Link to="/about/">About</Link>
          <br />
          <Link to="/terms-and-conditions/">Terms &amp; Conditions</Link>
          <br />
          <Link to="/privacy-policy/">Privacy policy</Link>{` `}
        </footer>
      </div>
    </>
  )
}

export default Layout
