import React from "react"
import styles from "./Banner.module.css"

const url = "/how-hired-com-is-revolutionizing-tech-recruitement/"

const Banner = () => <div className={styles.banner}>
  <h3>We're looking for talented developers</h3>
  <div class={styles.column}>
    <ul>
      <li>Work with prestigious clients</li>
      <li>Set your own rate and hours</li>
      <li>Flexible schedule and time off</li>
    </ul>
  </div>
  <div className={styles.column}>
    <a href={url} title="Apply">Apply</a>
  </div>
</div>

export default Banner