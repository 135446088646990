import React from "react"

const SuperLeaderBoard = () => <>
  <script id="mNCC" language="javascript" key="config" dangerouslySetInnerHTML={{
    __html: `
      medianet_width = "970";
      medianet_height = "90";
      medianet_crid = "391246889";
      medianet_versionId = "3111299";
    `,
  }}>
  </script>
  <script src="https://contextual.media.net/nmedianet.js?cid=8CU56S6DD" key="ad"></script>
</>

export { SuperLeaderBoard }
